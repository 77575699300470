<template>
  <v-responsive>
    <SubMenuStoreMobile
      v-if="$vuetify.breakpoint.smAndDown"
    ></SubMenuStoreMobile>
    <v-container
      :fluid="$vuetify.breakpoint.lgAndDown"
      style="max-width: 1440px"
    >
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuStore></SubMenuStore>
          
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>


        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="9"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-0 pt-0' : ''"
        >
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
            <v-divider></v-divider>
          </v-container>

          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>




          <v-card class="d-flex justify-center mt-10" flat>
            <v-img
              src="https://m.dhlottery.co.kr/images/mobile/common/logo_lotto645_large.png"
              max-width="180"
            ></v-img>
          </v-card>

          <v-responsive
            class="d-flex justify-center mt-10"
            flat
            style="
              background: #f0f7ff;
              padding: 23px 20px;
              max-width: 560px;
              margin: 0 auto;
              border-radius: 4px;
            "
          >
            <p style="font-size: 18px; text-align: center; color: #555">
              45개의 숫자중
              <strong style="color: #007bc3 !important">6개 맞히면 1등</strong>
            </p>
            <v-divider></v-divider>
            <p
              style="
                text-align: center;
                padding-top: 15px;
                padding-bottom: 0;
                margin-bottom: 0;
                color: #555;font-size:14px;
              "
            >
              매주 토요일 오후 8시 45분 추첨
            </p>
          </v-responsive>

          <div style="text-align: center; margin-top: 25px">
            <v-btn x-large color="blue darken-4" dark to="/lottowin/check/">
              당첨결과 조회하기
            </v-btn>
          </div>

          <v-card
            style="max-width: 560px; margin: 30px auto; border: 1px solid #eee"
            flat
          >
            <v-list two-line>
              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong>1등</strong></v-list-item-title>
                    <v-list-item-subtitle>
                      <div style="white-space:normal;">
                      6개 숫자 일치 시 당첨 <br /><span
                        >(해당 회차의 총 판매액에 의해 당첨금 결정)</span
                      ></div>

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>게임설명</strong></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <div style="white-space:normal;">
                      45개의 숫자 중 추첨된 6개 번호와 내가 선택한 숫자가
                      일치하는 개수에 따라 등위가 결정됩니다.
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-receipt</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>판매가격</strong></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <div style="white-space:normal;"> 1매 1,000원</div> </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>



              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>





        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Store",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "판매점 분석" },
      { text: "로또 소개" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],

    items: [
      { header: "Today" },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="font-weight-bold">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="font-weight-bold">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="font-weight-bold">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
    ],
  }),
};
</script>
<style></style>
